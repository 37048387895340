import './App.scss'

import type { FunctionComponent } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { wpNewsUrlRegex } from '@which/shared'
import { ApolloError } from '@apollo/client'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import type { KindeContextProps, State } from '@kinde-oss/kinde-auth-react'
import { KindeProvider } from '@kinde-oss/kinde-auth-react'

import { routes } from './routes'
import { isLocal } from './shared'
import { ErrorComponent } from './shared/components/Error/ErrorComponent'
import { CompareTrayContextProvider } from './shared/contexts/CompareTrayContext'
import { paywallUrls } from './shared/paywall-urls'
import { getGraphQLError } from './shared/utils/get-graphql-error'
import { watchDataLayer } from './shared/utils/watch-data-layer'

export const App: FunctionComponent = () => {
  const { pathname } = useLocation()
  const history = useHistory<AppHistory>()
  const shouldRemoveTrailingSlash = !wpNewsUrlRegex.test(pathname)
  // @PPE-291 Kinde login GB test
  const kindeLoginFeature = useFeatureIsOn('paywall-kinde-login')
  const [paywallState] = useState(paywallUrls())

  useEffect(() => {
    watchDataLayer()

    if (window.location.host === 'www.which.co.uk') {
      disableConsole()
    }
  }, [])

  useEffect(
    () =>
      history.listen(({ state }) => {
        if (!state?.updateQueryString && !history?.location?.hash) {
          window.scrollTo(0, 0)
        }
      }),
    [history]
  )

  const content = useMemo(
    () => (
      <CompareTrayContextProvider>
        <Switch key="content">
          {shouldRemoveTrailingSlash && <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />}
          {routes.map((props) => (
            <Route key={props.path as string} {...props} />
          ))}
          <Route
            component={() => (
              <ErrorComponent
                error={
                  new ApolloError({
                    graphQLErrors: [getGraphQLError('Page not found', '404')],
                  })
                }
              />
            )}
          />
        </Switch>
      </CompareTrayContextProvider>
    ),
    [pathname, shouldRemoveTrailingSlash]
  )

  // @PPE-291 Kinde login GB test
  if (
    kindeLoginFeature &&
    paywallState &&
    paywallState.kindeClientId &&
    paywallState.kindeDomain &&
    paywallState.redirectUri
  ) {
    return (
      <KindeProvider
        callbacks={{
          onSuccess: async (
            user: State['user'],
            state: Record<string, unknown>,
            context: KindeContextProps
          ) => {
            const domain = isLocal() ? 'localhost' : '.which.co.uk'

            const setKindeSession = async () => {
              if (context && context.getAccessToken) {
                const token = await context.getAccessToken()
                const kindeUser = (await context.getClaims()) as {
                  user_properties: { salesforce_id: { v: string } }
                }
                const kindeRedirect = sessionStorage.getItem('kindeRedirect')
                const date = new Date()
                date.setMonth(date.getMonth() + 1)
                const expireDate = date.toUTCString()
                document.cookie = `sessionId=${token};path=/; domain=${domain};expires=${expireDate};Secure;`
                document.cookie = `blaize_session=${token};path=/; domain=${domain};expires=${expireDate};Secure;`
                document.cookie = `master_id.analytics_flag=2;path=/; domain=${domain};expires=${expireDate}`
                document.cookie = `master_id=${kindeUser.user_properties.salesforce_id.v ?? ''};path=/; domain=${domain};expires=${expireDate}`

                if (kindeRedirect) {
                  location.replace(kindeRedirect)
                  sessionStorage.removeItem('kindeRedirect')
                } else {
                  window.history.replaceState({}, document.title, window.location.pathname)
                }
              }
            }

            await setKindeSession()
          },
        }}
        clientId={paywallState.kindeClientId}
        domain={paywallState.kindeDomain}
        logoutUri={paywallState.redirectUri}
        redirectUri={paywallState.redirectUri}
        useInsecureForRefreshToken={isLocal()}
        forceChildrenRender={true}
      >
        {content}
      </KindeProvider>
    )
  }
  return content
}

///////// IMPLEMENTATION /////////

type AppHistory = {
  updateQueryString: boolean
}

const disableConsole = () => {
  window.console = {
    ...window.console,
    log: () => '',
    error: () => '',
    warn: () => '',
    info: () => '',
  }
}
